<template>
  <div class="row g-3">
    <div v-for="post in posts" :key="post.link" class="col-12 col-lg-4 blogpost">
      <router-link :to="post.link">
        <figure>
          <img class="img-fluid img--animated-soft w-100"
               :src="getImageUrl(post.image)"
               :alt="post.title"
               :title="post.title"
               height="225"
               width="350">
        </figure>
      </router-link>

      <router-link :to="post.link">
        <h4 class="title_m title_blue my-3">
          {{ post.title }}
        </h4>
      </router-link>

      <p class="text_xl">
        {{ post.excerpt }}
      </p>

      <div class="text_bold text_m">
        <div class="row px-3">
          <div class="d-inline-block mr-3">
            <img class="rounded-circle img--animated-soft"
                 :src="getImageUrl(post.authorImage)"
                 :alt="post.author"
                 width="40"
                 height="40">
          </div>
          <div class="d-inline-block">
            {{ post.author }}<br>
            {{ post.date }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogCard",
  data() {
    return {
      posts: [
        {
          link: "/blog/intervju-nemanja-rakicevic",
          image: "Nemanja-Rakicevic-Serbian-Researchers.png",
          title: "Intervju: Nemanja Rakićević",
          excerpt:
            "Promocija naučnih dostignuća i umrežavanje istraživača predstavljaju važan temelj za napredak svake naučne zajednice...",
          author: "Milica Brković",
          authorImage: "milica.jpeg",
          date: "Mar 26, 2025"
        },
        {
          link: "/blog/m2l-2025",
          image: "m2l.png",
          title: "M2L 2025",
          excerpt:
            "Mediteranska letnja škola mašinskog učenja (M2L) ponovo okuplja talentovane istraživače i profesionalce iz oblasti veštačke inteligencije...",
          author: "Milica Brković",
          authorImage: "milica.jpeg",
          date: "Mar 21, 2025"
        },
        {
          link: "/blog/intervju-luka-jakovljevic",
          image: "Luka-Jakovljevic-Serbian-Researchers.png",
          title: "Intervju: Luka Jakovljević",
          excerpt:
            "U eri globalizacije i sve veće mobilnosti naučnika, mogućnost umrežavanja i razmene znanja postaje ključna za razvoj istraživačke zajednice...",
          author: "Milica Brković",
          authorImage: "milica.jpeg",
          date: "Mar 17, 2025"
        },
        {
          link: "/blog/eeml-2025",
          image: "eeml-2025-cover.png",
          title: "EEML 2025",
          excerpt:
            "Veštačka inteligencija se ubrzano razvija i oblikuje savremenu nauku i industriju, a kvalitetna edukacija i umrežavanje imaju presudnu ulogu u...",
          author: "Milica Brković",
          authorImage: "milica.jpeg",
          date: "Mar 13, 2025"
        },
        {
          link: "/blog/intervju-velimir-bulatovic",
          image: "Velimir-Bulatovic-Serbian-Researchers.png",
          title: "Intervju: Velimir Bulatović",
          excerpt:
            "Inovacija predstavlja proces pretvaranja novih ideja u vrednost, bilo kroz proizvode, usluge, poslovne modele ili druge nove načine rada. Reč je o složenom procesu...",
          author: "Milica Brković",
          authorImage: "milica.jpeg",
          date: "Mar 03, 2025"
        },
        {
          link: "/blog/platforma-srpski-istrazivaci",
          image: "blog.png",
          title: "Platforma Srpski Istraživači",
          excerpt:
            "Srbiju svake godine napusti na hiljade talentovanih ljudi, među kojima je veliki broj onih koji se bave naučno istraživačkim radom na vrhunskom nivou...",
          author: "Serbian Researchers",
          authorImage: "avatar.png",
          date: "Jun 28, 2021"
        }
      ]
    };
  },
  methods: {
    getImageUrl(imageName) {
      try {
        return require(`@/assets/blog/${imageName}`);
      } catch (e) {
        console.error("Image not found:", imageName);
        return "";
      }
    }
  }
};
</script>
