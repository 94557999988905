<template>
  <div>
    <header class="container header_white">
      <Navbar logoColor="true"></Navbar>
    </header>
    
    <section id="allBlogs" class="bg_lighgrey">
        <div class="container">
          <div class="row">
            <div class="container aos-init" data-aos="fade-up" data-aos-duration="1000">
              <div class="row">
                <div class="col-12">
                  <h3 class="title_xl title_lightblue">
                    Blog
                  </h3>
                  <p class="text_xl mt-3 mb-4">
                    Najnovije vesti.
                  </p>
                </div>
              </div>

              <BlogCard :data="blogs"></BlogCard>
            </div>
          </div>
        </div>
      </section>    
    <Footer bg="footer_bg-red"></Footer>
  </div>
</template>


<script>
import Navbar from "@/components/layout/Navbar"
import Footer from "@/components/layout/Footer"

import BlogCard from '@/components/shared/BlogCard'

export default{
    name: "AllBlogs",
    components: {
    Navbar,
    Footer,
    BlogCard
  },
  data() {
    return {
      blogs: [
        {},
      ]
    }
  },
}
</script>